.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.authentication-bg.enlarged {
  min-height: 100px;
}
.authentication-bg {
  background-size: cover;
  background-position: center;
}
.auth-title {
  background-color: #f7fafb;
  border-top: 1px solid #ecf2f4;
  border-bottom: 0px solid #ecf2f4;
  padding: 12px 2.25rem;
  margin: 0 -2.25rem 0%;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.authentication-bg-pattern {
  background-image: url(bg.png);
}
